// src/Localization/faTranslations.js
const faTranslations = {
  // home: 'خانه',
  // browseJobs: 'مشاغل را مرور کنید',
  // help: 'کمک',
  // categories:'دسته بندی ها',
  // contactUs: 'تماس با ما',
  // websiteItSoftware: 'وب سایت، IT و نرم افزار',
  // designMediaArchitecture: 'طراحی، رسانه و معماری',
  // dataEntryAdmin: 'ورود اطلاعات و مدیریت',
  // education: 'تحصیلات',
  // artificialIntelligence: 'هوش مصنوعی',
  // signIn: 'ورود',
  // join: 'پیوستن',
  profile: "پروفایل",
  VerifyEmail: "تأیید ایمیل",
  BillingandPayments: "صورت‌حساب و پرداخت‌ها",
  // account: 'حساب',
  // dashboard: 'داشبورد',
  // logout: 'خروج',
  Categories: "دسته بندی ها",
  Home: "صفحه اصلی",
  BrowseJobs: "مشاهده کارها",
  Help: "کمک",
  Contact: "با ما تماس بگیرید",
  Contact2: "مخاطب",
  SignIn: "ورود",
  Join: "پیوستن",
  OneStop: "بستر کاریابی یک مرحله ای در ایران",
  Match: "مهارت ها و علایق خود را با مشاغل عالی مطابقت دهید.",
  Simple: "فرآیند درخواست ساده - یک کلیک برای درخواست",
  Build: "ساخت یک پروفایل قوی برای جلب توجه کارفرمایان",
  Connect: "ارتباط با شرکت ها و آژانس های برتر در ایران.",
  TwentyM: "پروژه تحویل داده شده",
  Active: "فریلنسر فعال",
  Safe: "ایمن و مطمعن",
  Trustworthy: "قابل اعتماد و حرفه ",
  TotalFreelance: "کل فریلنسرها",
  Positive: "نظر مثبت",
  Order: "سفارشهای دریافت شده",
  Projects: "پروژه های تکمیل شده",
  Files: "فایل ها",
  File: "فایل",
  addAmt: "لطفا مقدار اضافه کنید",
  selectDel: "لطفا زمان تحویل را انتخاب کنید",
  greaterThen0: "لطفا مقدار بیشتر از 0 را اضافه کنید.",
  editProporal: "ویرایش پیشنهاد",
  coverLet: "نامه همراه باید حداقل 10 کاراکتر باشد.",
  Trusted: "مورد اعتماد بهترین های جهان",
  BrowseTalent: "استعدادها را بر اساس دسته مرور کنید",
  Find: ".آن را ساده میکند WorkWaveHub .تنها با چند کلیک بهترین مهارت ها و تجربه ها را برای پروژه بعدی خود بیابید",
  All: "همه دسته بندی ها",
  Website: "وب سایت، فناوری اطلاعات و نرم افزار",
  Design: "طراحی، رسانه و معماری",
  Data: "ورود اطلاعات و مدیریت",
  Education: "تحصیلات",
  Artificial: "هوش مصنوعی",
  Talented:
    "از استخدام استعدادهای برجسته برای کسب و کارتان استفاده کنید - بازار کار ایران",
  Search:
    "جستجو و ارتباط با مجموعه وسیعی از متخصصان ماهر در صنایع مختلف. پلت فرم نوآورانه ما فرآیند استخدام را ساده می کند و در زمان و منابع شما صرفه جویی می کند. پیدا کردن بهترینها برای موقعیت های باز شغلی شما-از توسعه دهندگان باتجربه گرفته تا جادوگران بازاریابی و هرچیز این ما بین. کسب و کار خود را با استعدادهای برتر ایرانی تقویت کنید.شروع کنید WorkWaveHub امروز با  ",
  The: "بهترین استعداد",
  OurPlatform:
    "پلتفرم ما شما را با مجموعه وسیعی از متخصصان ایرانی از پیش بررسی شده در صنایع مختلف مرتبط می کند.",
  Fast: "پیشنهادهای سریع",
  WorkWaveHubs:
    " WorkWaveHubفرایند کارامد  به شما امکان می‌دهد به سرعت شغل ارسال کنید و پیشنهادات را از نامزدهای واجد شرایط دریافت کنید. ",
  Quality: "کار با کیفیت",
  Our: "تمرکز ما بر متخصصان ماهر تضمین می کند که کار با کیفیت بالا را از متخصصان واجد شرایط و ماهر دریافت  کنید.",
  Be: "در کنترل باشد",
  WorkWaveHub2:
    "پلت فرم WorkWaveHub ابزارهای ارتباطی واضح و گزینه های پرداخت امن را فراهم می کند و به شما کنترل کامل بر کل فرآیند استخدام می دهد.",
  How: "WorkWaveHub چگونه کار می کند؟",
  Create: "پروفایل خود را ایجاد کنید",
  Showcase:
    "مهارت ها و تجربه خود را به نمایش بگذارید تا در بین شرکت های برتر ایرانی متمایز شوید.",
  FindPerfect: "مشاغل ایده آل را پیدا کنید",
  SearchBrowse: "هزاران فرصت را در صنایع مختلف در ایران جستجو و مرور کنید.",
  SimpleApp: "برنامه ساده",
  Apply:
    "با یک کلیک برای مشاغل درخواست دهید و با کارفرمایان بالقوه ارتباط برقرار کنید.",
  Manage: "حرفه خود را مدیریت کنید",
  Track:
    "مدیریت کنید برنامه ها را ردیابی کنید، هشدارهای شغلی را دریافت کنید وWorkWaveHubسفر شغلی خود را در ",
  Love: "عاشق کسب درآمد با Workwavehub",
  Clients: "مشتریان حرفه ای را در Workwavehub رتبه بندی می کنند",
  Customers: "95 درصد از مشتریان از دیدن مترجمان آزاد خود راضی هستند",
  AwardWinner: "برنده ی جایزه",
  HomeOw: "مالکیت خانه",
  Followus: "ما را دنبال کنید",
  Terms: "سیاست حفظ حریم خصوصی",
  Privacy: "سیاست حفظ حریم خصوصی",
  Site: "نقشه سایت ",
  AboutOne: "در باره",
  CategoriesOne: "دسته بندی ها",
  WebsiteSoft: "وب سایت، فناوری اطلاعات و نرم افزار",
  DesignMedia: "طراحی، رسانه و معماری",
  DataEntry: "ورود اطلاعات و مدیریت",
  Sales: "فروش و بازاریابی",
  Product: "منبع یابی و تولید محصول",
  Translation: "ترجمه و زبان ها",
  Freight: "باربری، حمل و نقل و حمل و نقل",
  EducationOne: "تحصیلات",
  ArtificialIntel: "هوش مصنوعی",
  Health: "خدمات بهداشت و درمان",
  Jobs: "مشاغل برای هر کسی",
  Support: "حمایت کردن",
  HelpSupport: "راهنما و پشتیبانی",
  Trust: "اعتماد و ایمنی",
  Subscribe: "اعتماد و ایمنی",
  Your: "آدرس ایمیل شما",
  Send: "ارسال",
  Downloads: "دانلودها از",
  AllRights: "تمامی حقوق محفوظ است @workwavehub",
  Explore: "با WorkWaveHub پتانسیل های شغلی خود را در ایران کشف کنید.",
  FindJob: "فرصت های شغلی هیجان انگیز را در صنایع مختلف پیدا کنید.",
  ShowcaseSkill: "مهارت ها و تجربیات خود را به شرکت های برتر نشان دهید.",
  Get: "بر اساس علایق خود با مشاغل عالی مطابقت داشته باشید.",
  ManageJob: "با ابزار WorkWaveHub جستجوی شغل خود را به طور موثر مدیریت کنید.",
  Already: "از قبل حساب کاربری دارید؟ ورود",
  JoinUs: "به ما بپیوند!",
  JoinGrow: "به جامعه رو به رشد متخصصان ما در ایران بپیوندید.",
  Employee: "کارمند",
  Personal: "حساب شخصی برای مدیریت تمام فعالیت های شما.",
  Employer: "کارفرما",
  Own: "مالک یا متعلق به یک شرکت باشید، این برای شماست.",
  Register: "ثبت حساب کارفرما!",
  RegisterEmployee: "تسجيل حساب الموظف!",
  WorkWaveHub:
    "WorkWaveHub برنامه یک مرحله ای شما برای یافتن استعدادهای مناسب در ایران است. ما از فناوری هوشمندانه برای تطبیق مهارت ها و علایق با شغل شما استفاده می کنیم.",
  First: "نام کوچک *",
  Last: "نام خانوادگی",
  Email: "آدرس ایمیل*",
  password: "كلمة المرور",
  CreatePass: "ایجاد رمز عبور*",
  I: "من با شرایط و ضوابط موافقم",
  SignUp: "ثبت نام",
  RegisterGoogle: "لطفاً شماره تلفن خود را تأیید کنید",
  Please: "لطفاً شماره تلفن خود را تأیید کنید",
  joinWWh:
    "با اطمینان به انجمن WorkWaveHub بپیوندید. شماره خود را امروز تأیید کنید!",
  Phone: "اطلاعات شما ایمن است",
  SendCode: "اطلاعات شما ایمن است",
  YourInfo: "اطلاعات شما ایمن است",
  selectCategoryFirst: "لطفاً ابتدا دسته را انتخاب کنید",
  MaxBudgetGreater: "لطفا حداکثر بودجه را بیشتر از بودجه تخمینی وارد کنید",
  Almost:
    "تقریباً وجود دارد! شماره تلفن خود را تأیید کنید تا حساب خود را ایمن کنید. تأیید شماره تلفن خود یک گام سریع برای تکمیل ثبت نام است.",
  We: " ما یک کد تأیید به شماره تلفن شما [5 رقم آخر پوشانده شده] ارسال کرده ایم. برای تکمیل ثبت نام WorkWaveHub خود کد زیر را وارد کنید.",
  Didnts:
    "کد را دریافت نکردید؟ معمولاً ظرف 30 ثانیه می رسد. اگر بیشتر طول کشید می توانید دوباره آن را بفرستید.",
  OTP: "تأیید OTP",
  WeOTP: "ما یک OTP به شماره تلفن شما ارسال کرده ایم!",
  resendOTP: "ارسال مجدد OTP؟",
  DidntOne: "OTP دریافت نکردید؟",
  Verify: "تأیید کنید",
  Skills: "مهارت هایی که به شما کمک می کند شغلی پیدا کنید",
  WorkWaveHubHelp:
    "WorkWaveHub می تواند به شما کمک کند مشاغلی را پیدا کنید که با مهارت های شگفت انگیز شما مطابقت دارد! امروز یک پروفایل عالی بسازید!",
  Suggested: "مهارت های پیشنهادی",
  Save: "ذخیره و ادامه",
  Complete: "مشخصات خود را کامل کنید!",
  YourChance:
    "نمایه شما شانس شما برای متمایز شدن از جمعیت است. به خوبی گرد و جالب خود را نشان دهید.",
  Next: "بعد",
  Tell: "برای ما کمی در مورد خودتان بگویید",
  Show: "به کارفرمایان نشان دهید که چرا منحصربه‌فرد هستید و چه چیزی را می‌توانید روی میز بیاورید.",
  What: "یک توضیح یک خطی در مورد خودتان بنویسید.",
  Describe: "خودت را توصیف کن",
  IStrong:
    "با فرزانه رفتم که پورتفولیوی قوی و سبک ارتباطی روشنی داشت. در طول پروژه، او من را در جریان پیشرفت خود قرار داد و بازخوردهای من را به طور یکپارچه در نظر گرفت. محصول نهایی فوق العاده است – وب سایتی که نه تنها زیباست، بلکه سرنخ های بیشتری نیز ایجاد می کند.  من خیلی خوشحالم که WorkWaveHub را پیدا کردم. این یک پلت فرم فوق العاده برای تجارت در ایران است تا با متخصصان با استعداد ارتباط برقرار کنند.",
  Darius: "داریوش فرهادی، مدیرعامل",
  Welcome: "خوش برگشتی!",
  To: "برای شروع این سفر، به ما بگویید چه نوع حسابی را می خواهید باز کنید.",
  EmailAddd: "آدرس ایمیل*",
  EnterEmailAdd: "آدرس ایمیل را وارد کن",
  Password: "کلمه عبور*",
  Enter: "رمز عبور خود را وارد کنید",
  Remember: "مرا به خاطر بسپار",
  Forgot: "رمز عبور را فراموش کرده اید؟",
  RegisterACCC: "ثبت حساب",
  Login: "با گوگل وارد شوید",
  ToReset:
    "برای بازنشانی رمز عبور، به ایمیل یا شماره موبایل خود نیاز دارید که قابل احراز هویت باشد",
  Reset: "بازنشانی رمز عبور",
  Back: "بازگشت به صفحه ورود",
  Check: "ایمیلتان را چک کنید",
  Open: "ایمیل خود را باز کنید",
  You: "ایمیل را دریافت نکرده اید؟  ارسال مجدد ",
  Successfully: "با موفقیت",
  YourPass1:
    "رمز عبور شما به روز شده است، لطفاً رمز عبور خود را مرتباً تغییر دهید تا این اتفاق نیفتد",
  Continue: "ادامه هید",
  DesignCreative: " طراحی و خلاقیت",
  Give: "با طراحی UX جامد، یک تجربه آنلاین صاف را به بازدیدکننده خود بدهید",
  WhatLook: " دنبال چی میگردی؟",
  ITandSoftware: "فناوری اطلاعات و نرم افزار",
  Medical: "پزشکی",
  Architecture: "معماری",
  Choose: "دسته را انتخاب کنید",
  SearchText: "جستجو کردن",
  noChatFound: "چتی پیدا نشد!",
  Filters: "فیلترها",
  ProjectTypeOne: "نوع پروژه",
  Hourly: "نرخ ساعتی",
  Fixed: "قیمت ثابت",
  Type: "یک پیام تایپ کنید",
  BudgetOne: "بودجه",
  Location: "محل",
  Clear: "پاک کردن",
  Top: "نتایج برتر",
  Sort: "مرتب سازی بر اساس",
  Latest: "آخرین",
  Bids: "مناقصه می دهد",
  Budget: "بودجه",
  USD: "دلار آمریکا",
  minutes: "دقیقه قبل",
  ProjectDetails: "جزئیات پروژه",
  Skill: "مهارت مورد نیاز",
  Place: "طرح پیشنهادی در این پروژه",
  Proposal: "مبلغ پیشنهادی",
  HowProj: "این پروژه چقدر طول می کشد؟",
  Select: "انتخاب کنید",
  Cover: "جلد نامه",
  Attachments: "پیوست ها",
  perHour: "در ساعت",
  Notifications: "اطلاعیه",
  phoneVerified: "شماره تلفن تایید شد",
  phoneNotVerified: "شماره تلفن تأیید نشده است",
  emailVerified: "ایمیل تأییده شده است",
  emailNotVerified: "ایمیل تایید نشد",
  AwardData:
    "هرچه کار شما در پلتفرم WorkWaveHub بیشتر باشد و وظایف را به موقع انجام دهید. بیشتر نمایه قوی خواهید داشت. نمایه قوی به شما کمک می کند تا در سطوح مختلف حرکت کنید. برای هر شغل، باید نظرات خود را از استخدام کننده شغل دریافت کنید. این بررسی‌ها می‌توانند هنگام مناقصه برای پروژه‌ها و تکمیل موفقیت‌آمیز آن‌ها مزیتی به شما بدهند.",
  Drag: "سایر پیشنهادات",
  Other: "سایر پیشنهادات",
  in: " در 7 روز",
  Replies: "در عرض یک ساعت پاسخ می دهد",
  Similar: "پروژه های مشابه ",
  SendProp: "ارسال پیشنهاد",
  Share: "این پروژه را با دیگران به اشتراک بگذارید",
  Copy: "لینک را کپی کنید",
  Report: "گزارش پروژه",
  Client: "تایید مشتری",
  Dashboard: "داشبورد",
  Logout: "خروج",
  My: "پیشنهادات من",
  Submitted: "پیشنهادات ارسال شده",
  Archive: "آرشیو پیشنهادات",
  ProjectName: "نام پروژه",
  Proposals: "پیشنهادات",
  Average: "پیشنهاد متوسط",
  MyProposal: "پیشنهاد من",
  Action: "عمل",
  Status: " وضعیت",
  MyProj: "پروژه های من",
  ProjectDetailsOne: " جزئیات پروژه",
  AllPop: "همه پیشنهادات",
  SkillReq: "مهارت مورد نیاز",
  Ongoing: "پروژه های در حال انجام",
  Cost: "هزینه",
  Duration: "مدت زمان",
  inDays: "(در روز)",
  MyBook: " نشانک من",
  Bookmarked: "پروژه های نشانه گذاری شده",
  Message: "پیام. ",
  SendMessage: "پیام فرستادن",
  Type: "یک پیام تایپ کنید",
  Delete: "چت را حذف کنید",
  ActiveOne: "فعال",
  MyProf: "پروفایل من",
  Profile: "جزئیات نمایه",
  Max: '"حداکثر اندازه فایل 1 مگابایت، حداقل ابعاد: 330x300 و فایل های مناسب می باشدjpg و .png"',
  Introduce: "خودتان را معرفی کنید",
  Languages: "سطح زبان ها",
  Language: "زبان",
  City: "شهر",
  Country: "کشور",
  TypeOne: "تایپ کنید",
  Specialization: "تخصص",
  Gender: "جنسیت",
  HourlyRate: "نرخ ساعتی",
  PhoneNo: "شماره تلفن",
  EmailAdd: "آدرس ایمیل",
  LastName: "نام خانوادگی",
  FirstName: "نام کوچک",
  SkillsOne: "مهارت ها",
  SkillsTwo: "مهارت 1",
  Point: "نقطه",
  Are: "آیا برای خارج شدن مطمئن هستید؟",
  Cancel: "لغو کنید",
  Yes: "بله",
  RegisterEmployee: "ثبت حساب کارمندی!",
  WorkWaveHubIran:
    "WorkWaveHub برنامه یک مرحله ای شما برای یافتن استعدادهای مناسب در ایران است. ما از فناوری هوشمندانه برای تطبیق مهارت ها و علایق با شغل شما استفاده می کنیم.",
  Full: "نام و نام خانوادگی",
  UserNameOne: "نام کاربری",
  PhoneOne: "شماره تلفن",
  TermsAnd: "من با شرایط و ضوابط موافقم",
  SignupOne: "ثبت نام",
  OTPVerify: " تأیید OTP",
  WeVerify:
    "ما یک کد تأیید را از طریق پیامک برای شما ارسال می کنیم. فقط آن را در برنامه وارد کنید، و شما آماده هستید.",
  SkillsInterst: "مهارت ها و علاقه ای که به دنبال آن هستید",
  Post: "شغل خود را پست کنید و بهترین استعدادها را برای شغل خود استخدام کنید - به همین سادگی!",
  SuggestedSkill: "مهارت های پیشنهادی",
  SaveContinue: "ذخیره و ادامه",
  SavePost: "ذخیره و ارسال کنید",
  CreateProj: "ایجاد پروژه ها",
  BrowseProf: "نمایه را مرور کنید",
  HireSkill:
    "متخصصان مستقل ماهر را برای هر پروژه ای از طریق اینترنت استخدام کنید.",
  LargestHub: "بزرگترین مرکز متخصصین مستقل ایران.",
  JobOpputunities: "انواع فرصت های شغلی.",
  EnjoySavings: "از صرفه جویی تا 90٪ لذت ببرید و قیمت های رایگان دریافت کنید.",
  PayOnly: "پرداخت فقط در صورت رضایت شما",
  FoPurpose: "به منظور مقررات صنعت، جزئیات شما مورد نیاز است.",
  EnterUserName: "نام کاربری را وارد کنید",
  MaxRow: "حداکثر 4 ردیف",
  Submite: "ارسال",
  EnterFullName: "نام کامل را وارد کنید",
  EnterPass: "رمز عبور را وارد کنید",
  Tell2: "مهارت های خود را به ما بگویید",
  ThiHelp: "این به ما کمک می کند مشاغلی را برای شما پیشنهاد کنیم.",
  ShownEmployee:
    "به کارفرمایان نشان دهید که چرا منحصربه‌فرد هستید و چه چیزی را می‌توانید روی میز بیاورید.",
  WriteLine: "یک توضیح یک خطی در مورد خودتان بنویسید.",
  BeginJourney:
    "برای شروع این سفر، به ما بگویید چه نوع حسابی را می خواهید باز کنید",
  LoginEmployee: "وارد شدن",
  SignUpEmployee: "ثبت نام",
  DontHavAccount: "حساب کاربری ندارید؟",
  BackToHome: "بازگشت به خانه",
  FullNameIsRequired: "نام کامل الزامی است",
  EmailIsRequired: "ایمیل مورد نیاز است",
  PasswordIsRequired: "رمز عبور مورد نیاز است",
  FullNameAtleastThree: "نام کامل باید بیش از 3 کاراکتر باشد",
  InvalidEmailFormat: "قالب ایمیل نامعتبر است",
  PassWordMustBe8Characters:
    "رمز عبور باید حداقل 8 کاراکتر و شامل یک حرف بزرگ باشد. یک حرف کوچک و یک کاراکتر خاص",
  YouMustAgree: "شما باید با شرایط و ضوابط موافقت کنید",
  UserNameIsRequired: "نام کاربری لازم است",
  UserNameIs3Characters: "نام کاربری باید بیش از 3 کاراکتر باشد",
  PleaseEnterPhoneNumber: "لطفا شماره تلفن را وارد کنید",
  PleaseEnterValidNumber: "لطفا یک شماره تلفن معتبر وارد کنید",
  Browse: "مرور کردن",
  CreateProjectAndStartActivities:
    "پروژه ها را ایجاد کنید و فعالیت های خود را شروع کنید.",
  BrowseProfileAndStartActivities:
    "پروفایل ها را مرور کنید و فعالیت های خود را شروع کنید.",
  UploadAttachments: "آپلود فایل های پیوست",
  NoDocumentsFound: "هیچ سندی یافت نشد",
  BackButton: "بازگشت",
  FileName: "نام فایل",
  CompletedProjects: "پروژه های انجام شده",
  Milestones: "نقاط عطف",
  MileStoneName: "نام نقطه عطف",
  Document: "سند",
  Filter: "فیلتر کنید",
  MyBookmarks: "نشانک های من",
  BookmarkProjects: "پروژه های نشانک ",
  MyProfile: "پروفایل من",
  Skills: "مهارت ها",
  ChangePassword: "رمز عبور را تغییر دهید",
  DeleteAccount: "حذف حساب کاربری",
  VerifyEmail: "تأیید ایمیل",
  State: "حالت",
  Save: "صرفه جویی",
  WhatAreTheMainSkills: "مهارت اصلی مورد نیاز برای کار شما چیست؟",
  UpdateSkill: "به روز رسانی مهارت",
  OldPassword: "رمز عبور قدیمی",
  EnterOldPassword: "رمز عبور قدیمی را وارد کنید",
  NewPassword: "رمز عبور جدید",
  EnterNewPassword: "رمز عبور جدید را وارد کنید",
  ConfirmPassword: "رمز عبور جدید را تأیید کنید",
  EnterConfirmPassword: "تایید رمز عبور جدید را وارد کنید",
  CloseAccount: "بستن حساب",
  WarningCloseAccount:
    "هشدار: اگر حساب خود را ببندید، اشتراک تمام اشتراک خود لغو می‌شوید و برای همیشه دسترسی را از دست خواهید داد.",
  DeleteAccountEmployee: "حذف",
  EmailAlreadyVerified: "ایمیل قبلاً تأیید شده است",
  SendOTP: "ارسال OTP",
  PleaseEnterOpt: "لطفا OTP را وارد کنید",
  OTP6Digit: "OTP باید 6 رقمی باشد",
  NewPasswodRequired: "رمز عبور جدید مورد نیاز است",
  ConfirmPasswordRequired: "تایید رمز عبور لازم است",
  PassWordNotMatch: "رمزهای ورود مطابقت ندارند",
  SentOPT: "ما یک OTP به شماره تلفن شما ارسال کرده ایم!",
  PleaseAddSkills: "لطفا مهارت ها را اضافه کنید",
  BrowseProject: "پروژه را مرور کنید",
  DashboardBookMark: "نشانک",
  DashboardProfile: "مشخصات",
  Bids: "مناقصه دو ماه دیگر به پایان می رسد",
  SkillsRequired: "مهارت های مورد نیاز",
  TimeDuration: "مدت زمان",
  AwardProject: "پروژه جایزه به",
  sendAnOffer: "یک پیشنهاد ارسال کنید",
  projectMilestone: "نقاط عطف پروژه",
  Milestone: "نقطه عطف",
  hireFor: "استخدام برای",
  subtotal: "جمع فرعی",
  marketPlaceFee: "هزینه بازار",
  pay: "پرداخت",
  estimatedTotal: "تخمین کلی",
  addNewMilestone: "نقطه عطف جدید اضافه کنید",
  areYouSure: "آیا مطمئنید که میخواهید حذف کنید؟",
  onceDeleted: `پس از حذف، بازیابی اطلاعات ممکن است امکان پذیر نباشد. برای تأیید روی «بله» یا برای بازگشت روی «لغو» کلیک کنید.`,
  Balance: "تعادل",
  paypalPaymentDes: "پرداخت آنلاین امن از طریق درگاه پی پال",
  stripePaymentDes: "پرداخت آنلاین امن از طریق درگاه Stripe",
  cryptoPaymentDes: "پرداخت آنلاین امن از طریق درگاه Crypto",
  Payment: "پرداخت",
  deleteProject: "آیا مطمئن هستید که می خواهید پروژه را حذف کنید؟",
  addBillingMethod: "افزودن روش صورتحساب",
  CoverLetter: "جلد نامه",
  EnterProjectDetails: "جزئیات پروژه خود را وارد کنید",
  NoProposalFound: "هیچ پیشنهادی یافت نشد",
  UpdateProposal: "به روز رسانی پیشنهاد",
  PostedProjects: "پروژه های ارسال شده",
  Chat: "چت کنید",
  Award: "جایزه",
  NoDataFound: "داده ای یافت نشد",
  EditMileStone: "Milestone را ویرایش کنید",
  MileStoneTitle: "عنوان نقطه عطف",
  MileStoneDescription: " شرح نقطه عطف",
  DepositeAmount: "مبلغ سپرده",
  amt: "میزان",
  Address: "نشانی",
  timeLeft: "زمان باقی مانده",
  Note: "توجه داشته باشید",
  doNotReload:
    "لطفاً تا زمانی که تراکنش در حال انجام است، به عقب یا این صفحه را بارگذاری مجدد نکنید",
  PaymentId: "شناسه پرداخت",
  suffAmt: "لطفا مبلغ کافی برای کارمزد تراکنش ارسال کنید",
  UpdateMileStone: "به روز رسانی",
  DeleteMilestone: " آیا مطمئن هستید که می خواهید نقطه عطف را حذف کنید؟",
  Finish: "پایان",
  AddMileStone: "Milestone را اضافه کنید",
  getOTP: "OTP را دریافت کنید",
  cryptoAdd: "آدرس کیف پول کریپتو",
  LeaveAReview: "نظر بدهید",
  HowToYouRating: "به کار کردن چه امتیازی می دهید؟",
  WriteAReview: "نظر بنویسید...",
  SubmitReview: "ارسال بررسی",
  BasicInformation: "اطلاعات اولیه",
  selectOpt: "لطفاً یک گزینه را انتخاب کنید",
  enterTitle: "لطفا عنوان را وارد کنید",
  enterMsg: "لطفا پیام را وارد کنید",
  detailsPRojectErr: "جزئیات پروژه باید حداقل 10 کاراکتر باشد.",
  titleCharErr: "عنوان باید حداقل 3 کاراکتر باشد.",
  validDepoistAmt: "لطفا یک مبلغ واریز معتبر اضافه کنید.",
  greaterDepoistAmt: "لطفا مبلغ سپرده بزرگتر از 0 را اضافه کنید.",
  coinPayment: "پرداخت های سکه ای",
  closeIn: "نزدیک",
  close: "بستن",
  firstNameReq: "نام مورد نیاز است",
  lastNameReq: "نام خانوادگی الزامی است",
  crytpoReq: "لطفا آدرس Crypto را وارد کنید",
  hourRateReq: "نرخ هر ساعت نمی تواند کمتر از 0 باشد",
  validCrypto: "لطفا آدرس معتبر USDT Crypto را وارد کنید",
  stateNameReq: "نام ایالت الزامی است",
  firstNameGreater3: "نام باید بیش از 3 کاراکتر باشد",
  lastNameGreater3: "نام خانوادگی باید بیش از 3 کاراکتر باشد",
  Category: "دسته بندی",
  ProjectTitle: "عنوان پروژه",
  ProjectDescription: "شرح پروژه",
  ProjectDuration: "مدت زمان پروژه",
  EstimateBudget: "برآورد بودجه",
  MinBudegte: "حداقل بودجه",
  MaxBudget: "حداکثر بودجه",
  ProjectTitle3: "عنوان پروژه باید حداقل 3 کاراکتر باشد.",
  ProjectDetail25: "جزئیات پروژه باید حداقل 25 کاراکتر باشد.",
  PleaseSelectCategory: "لطفاً یک دسته را انتخاب کنید.",
  PleaseSelectPaymentType: "لطفا نوع پرداخت را انتخاب کنید",
  PleaseSelectSkills: "لطفا مهارت ها را انتخاب کنید",
  PleaseSelectLocation: "لطفا مکان را انتخاب کنید",
  PleaseSelectDuration: "لطفا مدت زمان پروژه را انتخاب کنید",
  PleaseSelectPrice: "لطفا قیمت را انتخاب کنید",
  PleaseSelectMinBudget: "لطفا حداقل بودجه را انتخاب کنید",
  PleaseSelectMaxBudget: "لطفا حداکثر بودجه را انتخاب کنید",
  DragandDrop: "یک تصویر را در اینجا بکشید و رها کنید یا کلیک کنید",
  MaxBudgetShould: "حداکثر بودجه نباید بیشتر از تخمین بودجه انتخاب شده باشد",
  EnterValidNumber: "لطفا یک شماره معتبر وارد کنید",
  MaxMinGreater: "حداکثر بودجه باید بیشتر از حداقل بودجه باشد",
  MinBudgetShouldBe: "حداقل بودجه نباید بیشتر از تخمین بودجه انتخابی باشد",
  EditProject: "ویرایش پروژه",
  ProjectType: "لطفا نوع پروژه را انتخاب کنید",
  DepositeAmount: "مبلغ واریز",
  PnoneAlreadyExists: "شماره تلفن از قبل وجود دارد",
  SessionExpires:
    "جلسه شما به پایان می رسد. برای بازگشت، باید دوباره وارد شوید. آیا مطمئن هستید که می‌خواهید ادامه دهید؟",
  Settings: "تنظیمات",
  BillingandPayments: "صورت‌حساب و پرداخت‌ها",
  EmployeeAbout: "درباره کارمند",
  Reviews: "بررسی ها",
  NoReviews: "در حال حاضر هیچ بررسی وجود ندارد.",
  AwardEmployee: "کارمند جایزه",
  AwardEmployeeSeek:
    "من به دنبال یک توسعه دهنده برنامه با تجربه و کارآمد برای ایجاد یک برنامه شبکه اجتماعی نوآورانه برای پلتفرم iOS هستم",
  AwardRequest: "درخواست جایزه",
  Verification: "تایید",
  TopSkills: "مهارت برتر",
  TitleShouldBe5Characters: "عنوان باید حداقل 5 کاراکتر باشد.",
  DescriptionShouldBe5Characters: "توضیحات باید حداقل 5 کاراکتر باشد.",
  PleaseAddTime: "لطفا زمان اضافه کنید",
  PleaseAddDeposite: "لطفا مبلغ واریز را اضافه کنید",
  PleaseGiveStar: "لطفا حداقل 1 ستاره بدهید.",
  ReviewShouldbe5: "مرور باید حداقل 5 کاراکتر باشد.",
  LoadMore: "بارگذاری بیشتر",
  DonateOneTake: "به روشنایی زندگی جوانان در ایران کمک کنید",
  DonateTwoTake:
    "بیایید با هم تأثیر مثبتی بر زندگی فرزندان شایسته بگذاریم. امروز کمک مالی کنید و به روشن کردن راه برای روشن تر کمک کنید فردا.",
  Dontae: "اهدا کنید",
  FindSol: "برای شروع سریع در WorkWaveHub راه حل ها را پیدا کنید",
  LetsGet: "بیایید به شما کمک کنیم.",
  HaveTrouble:
    "اگر با حساب، پروژه یا پرداخت های خود مشکل دارید، نگران نباشید! تیم پشتیبانی ما همیشه خوشحال است که به شما کمک کند.",
  HowWeCan: "ما چگونه می توانیم به شما کمک کنیم؟",
  TitleContact: "عنوان",
  describePlease: "لطفا نیازهای خود را شرح دهید",
  contactone:
    " آیا مشکلی وجود دارد یا فقط یک سوال است؟ شما می توانید به ما اطلاع دهید یا هر چیزی را بپرسید.",
  contacttwo: "در تماس باشید",
  PricingFees: "قیمت و کارمزد",
  privacyPolicy: "سیاست حفظ حریم خصوصی",
  privacyPolicy1:
    'این خط‌مشی رازداری روش‌های ما را در مورد جمع‌آوری، استفاده و افشای اطلاعات شما در هنگام استفاده از خدمات ما ("سرویس") تشریح می‌کند. ',
  privacyPolicy2: "لینک سیاست حفظ حریم خصوصی",
  privacyPolicy3: "سیاست حفظ حریم خصوصی در لینک زیر قابل دسترسی است:",
  privacyPolicy4: "مرجع نهاد",
  privacyPolicy5:
    'نهاد مسئول داده های شخصی شما WorkWaveHub است که به عنوان "ما"، "ما" یا "ما" شناخته می شود.',
  privacyPolicy6: "برچسب گذاری خط مشی",
  privacyPolicy7:
    'این سند به عنوان "سیاست حفظ حریم خصوصی" برچسب گذاری شده است و اقدامات ما را در مورد مدیریت داده های شخصی نشان می دهد.',
  privacyPolicy8: "حساب",
  privacyPolicy9:
    " : یک حساب منحصر به فرد ایجاد شده برای شما برای دسترسی به خدمات ما.",
  privacyPolicy10: "وابسته",
  privacyPolicy11: ": نهادی که کنترل، کنترل یا تحت کنترل مشترک با یک طرف است.",
  privacyPolicy12: "برنامه",
  privacyPolicy13: ": به WorkWaveHub، نرم افزار ارائه شده توسط ما اشاره دارد.",
  privacyPolicy14: "شرکت",
  privacyPolicy15:
    ": WorkWaveHub که به عنوان «ما»، «ما» یا «ما» شناخته می‌شود.",
  privacyPolicy16: "کوکی ها",
  privacyPolicy17:
    ": فایل های کوچکی که در دستگاه شما قرار داده شده و حاوی جزئیات تاریخچه مرور شما هستند.",
  privacyPolicy18: "کشور",
  privacyPolicy19: ": اشاره به ایران است.",
  privacyPolicy20: "دستگاه",
  privacyPolicy21:
    ": هر دستگاهی که بتواند به سرویس دسترسی داشته باشد، مانند رایانه یا دستگاه تلفن همراه.",
  privacyPolicy22: "داده های شخصی",
  privacyPolicy23:
    ": اطلاعاتی که به یک فرد شناسایی شده یا قابل شناسایی مربوط می شود.",
  privacyPolicy24: "خدمات",
  privacyPolicy25: ": برنامه یا وب سایت یا هر دو.",
  privacyPolicy26: "ارائه دهنده خدمات",
  privacyPolicy27: " : هر نهادی که داده ها را از طرف ما پردازش می کند.",
  privacyPolicy28: "خدمات رسانه های اجتماعی شخص ثالث",
  privacyPolicy29:
    ": وب‌سایت‌ها یا شبکه‌های اجتماعی که به شما این امکان را می‌دهند که وارد شوید یا یک حساب کاربری برای سرویس ایجاد کنید.",
  privacyPolicy30: "داده های استفاده",
  privacyPolicy31:
    ": داده هایی که به طور خودکار جمع آوری می شوند و توسط سرویس یا زیرساخت آن تولید می شوند.",
  privacyPolicy32: "وب سایت",
  privacyPolicy33: ": WorkWaveHub، قابل دسترسی در",
  privacyPolicy34: "شما",
  privacyPolicy35: ": شخص حقیقی یا حقوقی که به سرویس دسترسی یا استفاده می کند.",
  privacyPolicy36: "جمع آوری و استفاده از داده های شخصی شما",
  privacyPolicy37: "انواع داده های جمع آوری شده",
  privacyPolicy38: "اطلاعات تماس",
  privacyPolicy39:
    "در حین استفاده از خدمات ما، ممکن است از شما بخواهیم اطلاعات شناسایی شخصی خاصی را در اختیار ما قرار دهید که می تواند برای تماس یا شناسایی شما استفاده شود. ",
  privacyPolicy40: "آدرس ایمیل",
  privacyPolicy41: "نام و نام خانوادگی",
  privacyPolicy42: "شماره تلفن",
  privacyPolicy43: "آدرس فیزیکی",
  privacyPolicy44:
    "برای هر گونه سوال در مورد این سیاست حفظ حریم خصوصی یا اگر مایلید از حقوق خود در مورد داده های شخصی خود استفاده کنید، می توانید با ما تماس بگیرید:",
  privacyPolicy45: "ایمیل",
  privacyPolicy46: "وب سایت",
  privacyPolicy47: "تلفن",
  privacyPolicy48: "قابلیت دسترسی",
  privacyPolicy49:
    "سیاست حفظ حریم خصوصی در یک مرورگر وب استاندارد قابل دسترسی است و نیازی به پلاگین یا نرم افزار اضافی ندارد. ",
  privacyPolicy50: "تفسیر و تعاریف",
  privacyPolicy51: "تفسیر",
  privacyPolicy52: "کلمات با حروف بزرگ در اینجا معانی خاصی دارند. ",
  privacyPolicy53: "تعاریف",
  privacyPolicy54: "برای این سیاست حفظ حریم خصوصی:",
  privacyPolicy55:
    "ما ممکن است از داده های شخصی شما برای اهداف مختلف استفاده کنیم، از جمله:",
  privacyPolicy56: "ارائه و نگهداری خدمات ما",
  privacyPolicy57: "مدیریت حساب شما",
  privacyPolicy58: "تماس با شما برای به روز رسانی و اطلاعات تبلیغاتی.",
  privacyPolicy59: "تجزیه و تحلیل داده ها برای بهبود خدمات ما.",
  privacyPolicy60: "به اشتراک گذاری داده های شخصی شما",
  privacyPolicy61:
    "ما ممکن است اطلاعات شخصی شما را در شرایط زیر به اشتراک بگذاریم:",
  privacyPolicy62: "با ارائه دهندگان خدمات",
  privacyPolicy63: ": برای نظارت و تجزیه و تحلیل استفاده.",
  privacyPolicy64: "برای نقل و انتقالات تجاری",
  privacyPolicy65: ": در طول مذاکرات ادغام یا تملک.",
  privacyPolicy66: "با وابسته ها",
  privacyPolicy67: ": با شرکت مادر یا شرکت های تابعه ما.",
  privacyPolicy68: "با شرکای تجاری",
  privacyPolicy69: ": برای ارائه محصولات یا خدمات خاص.",
  privacyPolicy70: "با رضایت شما",
  privacyPolicy71: ": برای هر هدف دیگری که با آن موافقت می کنید.",
  privacyPolicy72:
    "ما داده های شخصی شما را فقط تا زمانی که برای اهداف ذکر شده در این سیاست حفظ حریم خصوصی لازم باشد حفظ می کنیم. ",
  privacyPolicy73:
    "اطلاعات شما ممکن است به رایانه هایی که خارج از حوزه قضایی شما قرار دارند منتقل شود. ",
  privacyPolicy74: "شما حق دارید اطلاعات شخصی خود را حذف یا درخواست کمک کنید. ",
  privacyPolicy75:
    "ممکن است تحت شرایط زیر اطلاعات شخصی شما را با حسن نیت افشا کنیم:",
  privacyPolicy76:
    "ما برای امنیت داده های شخصی شما ارزش قائل هستیم اما نمی توانیم امنیت مطلق را تضمین کنیم. ",
  privacyPolicy77: "استفاده از داده های شخصی شما",
  privacyPolicy78: "تعهدات قانونی",
  privacyPolicy79:
    ": در صورت لزوم توسط قانون یا در پاسخ به درخواست های معتبر مقامات دولتی.",
  privacyPolicy80: "حفظ حقوق شرکت",
  privacyPolicy81: ": برای حفظ و دفاع از حقوق و اموال خود.",
  privacyPolicy82: "جلوگیری از اشتباهات احتمالی",
  privacyPolicy83: ": برای جلوگیری یا بررسی تخلفات احتمالی در ارتباط با سرویس.",
  privacyPolicy84: "اطمینان از ایمنی کاربران",
  privacyPolicy85: ": برای حفظ امنیت شخصی کاربران سرویس یا عموم مردم.",
  privacyPolicy86:
    "خدمات ما برای افراد زیر 13 سال در نظر گرفته نشده است. ما آگاهانه داده های شخصی افراد زیر سن قانونی را جمع آوری نمی کنیم. ",
  privacyPolicy87:
    "خدمات ما ممکن است حاوی پیوندهایی به وب سایت های شخص ثالث باشد. ",
  privacyPolicy88:
    "ممکن است سیاست حفظ حریم خصوصی خود را به صورت دوره ای به روز کنیم. ",
  privacyPolicy89: "افشای تفصیلی",
  privacyPolicy90: "انواع داده های شخصی و حساس جمع آوری شده",
  privacyPolicy91: "ما ممکن است داده های شخصی را جمع آوری کنیم، از جمله:",
  privacyPolicy92: "دسترسی شخص ثالث",
  privacyPolicy93:
    "ما ممکن است داده های شما را با اشخاص ثالث همانطور که در این خط مشی رازداری توضیح داده شده است، از جمله شرکای تجزیه و تحلیل و تبلیغات، به اشتراک بگذاریم. ",
  privacyPolicy94: "اقدامات امنیتی",
  privacyPolicy95: "حفظ اطلاعات شخصی شما",
  privacyPolicy98: "انتقال داده های شخصی شما",
  privacyPolicy99:
    "اطلاعات شما، از جمله داده های شخصی، در دفاتر عملیاتی شرکت و در هر مکان دیگری که طرف های درگیر در پردازش در آن قرار دارند پردازش می شود. ",
  privacyPolicy100:
    "ما اقدامات امنیتی را برای محافظت از داده های شما اجرا می کنیم، از جمله رمزگذاری، کنترل های دسترسی و سرورهای ایمن.",
  privacyPolicy101: "سیاست های حفظ و حذف داده ها",
  privacyPolicy102: "افشای اطلاعات شخصی شما",
  privacyPolicy103:
    "ما داده های شخصی شما را فقط تا زمانی که برای تحقق اهداف ذکر شده در این خط مشی ضروری است حفظ می کنیم. ",
  privacyPolicy104: "حقوق کاربر",
  privacyPolicy105: "کاربران حق دارند:",
  privacyPolicy106:
    "شما می توانید از طریق تنظیمات حساب کاربری خود یا با تماس با ما به اطلاعات شخصی خود دسترسی داشته باشید.",
  privacyPolicy107:
    "می توانید از طریق تنظیمات حساب خود یا با تماس مستقیم با ما درخواست اصلاح کنید.",
  privacyPolicy108:
    "برای درخواست حذف، لطفاً با ایمیل ارائه شده با ما تماس بگیرید:",
  privacyPolicy109: "مطابقت با خط‌مشی‌های Google Play",
  privacyPolicy110:
    "این خط‌مشی رازداری برای مطابقت با خط‌مشی‌های برنامه برنامه‌نویس Google Play طراحی شده است. ",
  privacyPolicy111: "پیوند در فهرست فروشگاه",
  privacyPolicy112:
    ": این خط مشی رازداری به طور برجسته در فهرست فروشگاه Google Play ما پیوند داده شده است تا دسترسی آسان را برای کاربران فراهم کند.",
  privacyPolicy113: "مرجع در برنامه",
  privacyPolicy114: "امنیت داده های شخصی شما",
  privacyPolicy115:
    ": در داخل برنامه، ما یک مرجع واضح به این خط مشی حفظ حریم خصوصی ارائه می دهیم و اطمینان می دهیم که کاربران به راحتی می توانند نحوه مدیریت داده های خود را بیابند و درک کنند.",
  privacyPolicy116: "حریم خصوصی کودکان",
  privacyPolicy117: "پاک کردن برچسب زدن",
  privacyPolicy118:
    ': این سند به وضوح به عنوان "سیاست حفظ حریم خصوصی" برچسب گذاری شده است تا از هرگونه سردرگمی در مورد هدف و محتوای آن جلوگیری شود.',
  privacyPolicy119: "پیوند به وب سایت های دیگر",
  privacyPolicy120: "نقطه تماس",
  privacyPolicy121:
    ": ما یک نقطه تماس اختصاصی در بالا برای سؤالات کاربر در مورد حریم خصوصی ارائه کرده‌ایم که به کاربران امکان می‌دهد با هرگونه سؤال یا نگرانی در مورد داده‌های شخصی خود ارتباط برقرار کنند.",
  privacyPolicy122: "تغییرات در این سیاست حفظ حریم خصوصی",
  privacyPolicy123: "قابلیت دسترسی",
  privacyPolicy124:
    ": این خط‌مشی رازداری بر روی یک URL فعال و عمومی میزبانی می‌شود که دارای محفظه جغرافیایی نیست و به طور کامل در مرورگرهای وب استاندارد قابل دسترسی است، این اطمینان را می‌دهد که کاربران به راحتی می‌توانند شیوه‌های حریم خصوصی ما را بخوانند و درک کنند.",
  privacyPolicy125: "شفافیت مدیریت داده ها",
  privacyPolicy126:
    ": ما در مورد نحوه جمع آوری، استفاده و اشتراک گذاری داده های شخصی شما، همانطور که در این خط مشی مشخص شده است، شفاف هستیم. ",
  privacyPolicy131: "اطلاعات شخصی خود را حذف کنید",
  privacyPolicy127: "اطلاعات پرداخت و ذخیره داده ها",
  privacyPolicy128:
    "هنگامی که از طریق پلتفرم ما پرداختی انجام می دهید، ما اطلاعات مربوط به پرداخت را جمع آوری و ذخیره می کنیم تا تراکنش های شما را تسهیل کنیم. ",
  privacyPolicy129: "شناسه تراکنش",
  privacyPolicy130:
    ": برای پردازشگرهای پرداخت شخص ثالث (به عنوان مثال، Stripe، PayPal، CoinPayment)، شناسه تراکنش را برای اهداف مرجع و ردیابی ذخیره می کنیم.",
  privacyPolicy132: "آدرس رمزنگاری USDT",
  privacyPolicy133:
    ": اگر از ارز دیجیتال برای پرداخت استفاده می کنید، ما آدرس رمزارز USDT مرتبط با حساب شما را برای تکمیل تراکنش ذخیره می کنیم.",
  privacyPolicy134:
    "ما این داده ها را فقط در صورت لزوم برای انجام تعهدات قانونی و نظارتی، حل اختلاف و نگهداری سوابق تراکنش حفظ می کنیم. ",
  pricingPlans: `قیمت و کارمزد`,
  deletionPolicy: `سیاست حذف`,
  deletionPolicy1: `حذف حساب: می توانید انتخاب کنید که حساب خود را حذف کنید
                پلتفرم (یعنی وب سایت`,
  deletionPolicy2: `و/یا محل کار برنامه تلفن همراه) و هر گونه اطلاعات
                در هر مقطع زمانی با آن مرتبط است. چنین درخواستی برای
                حذف باید کتبا ارسال شود`,
  deletionPolicy3: `یا می توانید وارد حساب کاربری خود شده و حذف اکانت را بررسی کنید
                گزینه ای که در آن می توانید حساب موجود خود را حذف کنید. در رویداد
                که درخواست حذف حساب یا داده های خود را داشته باشید،
                دیگر نمی توانید به پلتفرم دسترسی داشته باشید و/یا از آن استفاده کنید
                خدمات، و ما این حق را برای خود محفوظ می داریم که هیچ کدام را به شما ارائه نکنیم
                خدمات از طریق پلتفرم در صورت درخواست شما، ما
                ممکن است اطلاعات شما (شخصی یا غیره) را حذف کند یا
                آن را از هویت خارج کنید تا ناشناس باشد و دیگر نباشد
                منتسب به شما با وجود هر چیزی که در
                سیاست حفظ حریم خصوصی، ممکن است برخی یا تمام اطلاعات شما را در آن نگه داریم
                مطابق با خط‌مشی‌ها و/یا الزامات ما در زیر
                قانون قابل اجرا. اصطلاحاتی که در اینجا تعریف نشده اند دارای معنی هستند
                در سیاست حفظ حریم خصوصی به آنها اختصاص داده شده است.`,
  termsAndCondition: "شرایط و ضوابط",
  trustAndSafety: "اعتماد و ایمنی",
  trustAndSafety1: `یافتن ارائه‌دهنده خدمات کامل آسان‌تر شد`,
  trustAndSafety2: `وقتی نوبت به یافتن بهترین ابزار و خدمات برای شما می رسد
            کسب و کار، بررسی ها و شهرت اهمیت دارد. به همین دلیل است که
            بازار WorkWaveHub مقایسه، انتخاب و اعتماد را آسان می کند
            فروشندگانی که با آنها کار می کنید`,
  trustAndSafety3: `در اینجا به این صورت است:`,
  trustAndSafety4: `رتبه بندی و سطوح فروشنده:`,
  trustAndSafety5: `ببین دیگه چی
              کسب و کارها فکر می کنند! WorkWaveHub رتبه بندی شفاف و واضح فروشنده را نمایش می دهد
              سطوح، به شما یک عکس فوری سریع از عملکرد هر فروشنده می دهد.`,
  trustAndSafety6: `بازخورد خریدار:`,
  trustAndSafety7: `با خواندن واقعی از رتبه بندی فراتر بروید
              نظرات مشتریان این به شما امکان می دهد از نزدیک ببینید چه چیز دیگری
              کسب و کارها کار با یک فروشنده خاص را تجربه کرده اند.`,
  trustAndSafety8: `با داشتن تمام این اطلاعات در دستان شما، می توانید آگاه شوید
            تصمیم بگیرید و ارائه دهندگان خدمات عالی را برای کمک به خود بیابید
            رونق کسب و کار`,
  trustAndSafety9: `گرفتن تصمیمات آگاهانه کلیدی برای به دست آوردن بیشترین بهره است
            WorkWaveHub. به همین دلیل است که ما آن را آسان می کنیم تا ببینیم چه چیز دیگری
            کسب و کارها به فروشندگانی که مد نظر شما هستند فکر می کنند.`,
  trustAndSafety10: `رتبه بندی فروشنده:`,
  trustAndSafety11: `بعد از اینکه فروشنده یک پروژه را تمام کرد
              (به نام Gig on WorkWaveHub)، خریداران می توانند تجربه خود را ارزیابی کنند.
              این رتبه بندی ها بر اساس معیارهای واضح است، بنابراین شما می توانید دقیقا ببینید
              نحوه عملکرد فروشنده در مناطق مختلف`,
  trustAndSafety12: `نظرات خریداران:`,
  trustAndSafety13: `چیزی بیش از یک امتیاز ستاره می خواهید؟
              مشکلی نیست! خریداران همچنین می توانند نظرات کتبی خود را در مورد آنها بنویسند
              تجربه کار با فروشنده این به شما اجازه می دهد تا ببینید چه چیز دیگری
              کسب و کارها دوست داشتند (یا دوست نداشتند) با آنها کار کنند.`,
  trustAndSafety14: `با اطمینان خرید و فروش کنید`,
  trustAndSafety15: `روزانه میلیون ها تراکنش در WorkWaveHub انجام می شود. ما میدانیم
            امنیت مهم است، بنابراین ما از جدیدترین و بهترین فناوری استفاده می کنیم
            تا پول و اطلاعات خود را ایمن نگه دارید. یعنی می توانید روی آن تمرکز کنید
            یافتن سرویس یا کنسرت عالی، بدون نگرانی در مورد کلاهبرداری یا
            نقض داده ها`,
  trustAndSafety16: `اطلاعات خصوصی می ماند:`,
  trustAndSafety17: `ما برای حریم خصوصی شما ارزش قائل هستیم
              و هرگز اطلاعات شخصی شما را با کسی خارج از آن به اشتراک نخواهد گذاشت
              WorkWaveHub. اطلاعات شما همیشه نزد ما امن است.`,
  trustAndSafety18: `پرداخت های ایمن و مطمئن:`,
  trustAndSafety19: `نیازی به نگرانی نیست
              اطلاعات پول یا کارت اعتباری شما همه معاملات اتفاق می افتد
              به طور ایمن در پلت فرم ما، چه با کارت اعتباری پرداخت کنید،
              پی پال یا روش دیگری. ما بالاترین امنیت را دنبال می کنیم
              استانداردهایی برای ایمن نگه داشتن اطلاعات شما`,
  trustAndSafety20: `با اطمینان ارتباط برقرار کنید:`,
  trustAndSafety21: `پیام های امن ما
              این سیستم به شما امکان می دهد با هر فروشنده ای که در آن حضور دارید چت کنید و فایل ها را به اشتراک بگذارید
              WorkWaveHub بدون نگرانی.`,
  pricingFees: `برای کارمندان`,
  pricingFees1: `به عنوان یک کارمند آزاد در WorkWaveHub، خدماتی را متحمل خواهید شد
                کارمزد بر درآمد شما ساختار هزینه ها به شرح زیر است:`,
  pricingFees2: `پروژه هایی معادل یا بیش از 200 دلار`,
  pricingFees3: `هزینه خدمات 7 درصد اعمال می شود.`,
  pricingFees4: `مثال`,
  pricingFees5: `برای یک پروژه 200 دلاری، هزینه خدمات است
                      7 درصد که به 14 دلار می رسد. پرداخت نهایی شما 186 دلار خواهد بود.`,
  pricingFees6: `برای یک پروژه 300 دلاری،
                      هزینه خدمات 7 درصد است که به 21 دلار می رسد. فینال شما
                      پرداخت 279 دلار خواهد بود.`,
  pricingFees7: `پروژه های زیر 200 دلار`,
  pricingFees8: `هزینه خدمات 9 درصد اعمال می شود.`,
  pricingFees9: `برای یک پروژه 150 دلاری،
                      هزینه خدمات 9٪ است که بالغ بر 13.50 دلار است. فینال شما
                      پرداخت 136.50 دلار خواهد بود.`,
  pricingFees10: `هنگام ایجاد کار خود باید یک آدرس رمزنگاری معتبر ارائه دهید
                حساب. پس از تکمیل پروژه و دریافت تاییدیه معتبر
                از طرف کارفرما، پرداخت شما ظرف 24 پردازش خواهد شد
                ساعت به حساب رمزنگاری شما در USDT.`,
  pricingFees11: `برای کارفرمایان`,
  pricingFees12: `به عنوان یک کارفرما در WorkWaveHub، هزینه خدماتی را متحمل خواهید شد
                هزینه پروژه ساختار هزینه ها به شرح زیر است:`,
  pricingFees13: `پروژه هایی معادل یا بیش از 200 دلار`,
  pricingFees14: `هزینه خدمات 7 درصد اعمال می شود.`,
  pricingFees15: `برای یک پروژه 200 دلاری،
                      هزینه خدمات 7 درصد است که به 14 دلار می رسد. مجموع شما
                      مقدار اضافه کردن 214 دلار خواهد بود.`,
  pricingFees16: `برای یک پروژه 300 دلاری،
                      هزینه خدمات 7 درصد است که به 21 دلار می رسد. مجموع شما
                      مقدار اضافه کردن 321 دلار خواهد بود.`,
  pricingFees17: `هزینه خدمات 9 درصد اعمال می شود.`,
  pricingFees18: `برای یک پروژه 150 دلاری،
                      هزینه خدمات 9٪ است که بالغ بر 13.50 دلار است. مجموع شما
                      مقدار اضافه کردن 163.50 دلار خواهد بود.`,
  pricingFees19: `هنگام ایجاد یک پروژه، باید بودجه مورد نیاز را اضافه کنید
                از طریق PayPal، Stripe یا Crypto. پس از تایید تکمیل شده
                پروژه از کارمند، مبلغ از شما کسر می شود
                صندوق WorkWaveHub و به کارمند آزاد منتقل شد
                حساب.`,
};

export default faTranslations;
