import * as React from "react";
import Box from "@mui/material/Box";
import { Container, Typography } from "@mui/material";
import fb from "../../Assets/Images/facebook-01.png";
import twitter from "../../Assets/Images/twitter.png";
// import yt from "../../Assets/Images/youtube.png";
import insta from "../../Assets/Images/instagram.svg";
import twitch from "../../Assets/Images/twitch.png";
import play from "../../Assets/Images/GooglePlay.png";
import App from "../../Assets/Images/Appstore.png";
import { useTranslation } from "react-i18next";
import "../../Localization/i18n";
import "./Footer.css";
import { Link } from "react-router-dom";
import { getAPI } from "../../Services/Api";

export default function Footer() {
  const [projectData, setProjectData] = React.useState([]);
  React.useEffect(() => {
    getProjectData();
  }, []);

  const getProjectData = async () => {
    try {
      const response = await getAPI("project/dropdown");
      if (response.statusCode === 200) {
        setProjectData(response?.data);
      }
    } catch (error) {
      console.log("error===", error);
    }
  };

  const { t } = useTranslation();
  return (
    <Box className="" color="black">
      <div className="footer" id="footerBG">
        <Container
          maxWidth="xl"
          sx={{ px: { md: 8, xl: 4, xxl: 4 }, width: "100vw" }}
        >
          <Box className="" sx={{ py: 1 }}>
            <Box
              className="headSection"
              sx={{ borderBottom: 1, py: 3, borderColor: "#fff", mr: "35px" }}
            >
              <div className="">
                {/* <Link to="/terms-and-conditions" className="Lnk">
                  <Typography
                    className="plZero"
                    variant="p"
                    sx={{
                      color: "#fff",
                      px: 2,
                      paddingLeft: 0,
                      textDecoration: "none",
                    }}
                  >
                    {t("Terms")}
                  </Typography>
                </Link>
                <Link to="/privacypolicy" className="Lnk">
                  <Typography
                    className="plZero"
                    variant="p"
                    sx={{ color: "#fff", px: 2 }}
                  >
                    {t("Privacy")}
                  </Typography>
                </Link>
                <Typography
                  className="plZero"
                  variant="p"
                  sx={{ color: "#fff", px: 2 }}
                >
                  {t("Site")}{" "}
                </Typography> */}
              </div>
              <Box
                sx={{ display: "flex", alignItems: "center" }}
                className="catBtnTop followSection"
              >
                <Typography
                  variant="p"
                  sx={{ color: "#fff", px: 2, paddingLeft: 0 }}
                >
                  {t("Followus")}
                </Typography>
                <a href="https://www.facebook.com/people/Work-Wave-Hub/61559906970775/">
                  <img className="socialIco" src={fb} alt="fb" />
                </a>
                <a href="https://twitter.com/workwavehub">
                  <img className="socialIco" src={twitter} alt="twitter" />
                </a>
                <a href="https://www.instagram.com/workwavehub">
                  <img
                    style={{ opacity: 0.76 }}
                    className="socialIco"
                    height={"24px"}
                    width={"24px"}
                    src={insta}
                    alt="insta"
                  />
                </a>
                {/* <img className="socialIco" src={yt} alt="fb" /> */}
                <a href="https://t.me/workwavehub">
                  <img className="socialIco" src={twitch} alt="twitch" />
                </a>
              </Box>
            </Box>
          </Box>
          <Box sx={{ py: 4 }}>
            <div className="content">
              <div className="services">
                <h4 className="mainFooteHead">{t("AboutOne")}</h4>
                <Link to="/privacypolicy" className="Lnk">
                  <p>
                    <a className="textSubFoote" href="#/">
                      {t("Privacy")}
                    </a>
                  </p>
                </Link>
                <Link to="/terms-and-conditions" className="Lnk">
                  <p>
                    <a className="textSubFoote" href="#/">
                      {t("Terms")}
                    </a>
                  </p>
                </Link>
              </div>
              <div className="social-media">
                <h4 className="mainFooteHead">{t("CategoriesOne")}</h4>
                {projectData?.categories?.slice(0, 7)?.map((ele) => (
                  <p key={ele?._id}>
                    <a className="textSubFoote" href="#/">
                      {ele?.name}
                    </a>
                  </p>
                ))}
              </div>
              <div className="links">
                <h4 className="mainFooteHead">{t("Support")}</h4>
                <Link to="/help" className="Lnk">
                  <p>
                    <a className="textSubFoote">{t("HelpSupport")}</a>
                  </p>
                </Link>
                <Link to="/contact" className="Lnk">
                  <p>
                    <a className="textSubFoote" href="#/">
                      {t("Contact")}
                    </a>
                  </p>
                </Link>
              </div>
              <div className="details">
                {/* <h4 className="mainFooteHead">{t("Subscribe")}</h4>
                <div className="searchbox-wrap">
                  <input type="text" placeholder={t("Your")} />
                  <button>
                    <span>{t("Send")}</span>{" "}
                  </button>
                </div> */}
                <Box className="" sx={{ pb: 4 }}>
                  <h4 className="mainFooteHead">{t("Downloads")}</h4>
                  <Box
                    className=""
                    sx={{ display: "flex", flexDirection: "column" }}
                  >
                    <a
                      href="https://play.google.com/store/apps/details?id=com.app.workwavehub"
                      target="_blank"
                    >
                      <img
                        src={play}
                        className="downloadImage"
                        width={200}
                        alt="play"
                      />
                    </a>
                    <a
                      href="https://apps.apple.com/us/app/workwavehub/id6657975299"
                      target="_blank"
                    >
                      <img
                        src={App}
                        className="downloadImage"
                        width={200}
                        alt="play"
                      />
                    </a>
                  </Box>
                </Box>
              </div>
            </div>
          </Box>
          <footer>
            <hr />
            <Typography sx={{ color: "#fff" }}>{t("AllRights")} </Typography>
          </footer>
        </Container>
      </div>
    </Box>
  );
}
