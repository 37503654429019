// src/Localization/enTranslations.js
const enTranslations = {
  // home: 'Home',
  // browseJobs: 'Browse Jobs',
  // help: 'Help',
  // contactUs: 'Contact Us',
  // categories:'Categories',
  // websiteItSoftware: 'Website, IT & Software',
  // designMediaArchitecture: 'Design, Media & Architecture',
  // dataEntryAdmin: 'Data Entry & Admin',
  // education: 'Education',
  // artificialIntelligence: 'Artificial Intelligence',
  // signIn: 'Sign In',
  // join: 'Join',
  profile: "Profile",
  // account: 'Account',
  // dashboard: 'Dashboard',
  // logout: 'Logout',

  Home: "Home",
  BrowseJobs: "Browse Jobs",
  Help: "Help",
  Contact: "Contact Us",
  Contact2: "Contact",
  SignIn: "Sign in",
  Join: "Join",
  OneStop: "One-Stop Job Search Platform in Iran",
  Match: "Match your skills & interests to perfect jobs.",
  Simple: "Simple application process - one tap to apply!",
  Build: "Build a strong profile to stand out to employers.",
  Connect: "Connect with top companies & agencies in Iran.",
  TwentyM: "Project Delivered",
  Active: "Active Freelancer",
  Safe: "Safe & Secure",
  Trustworthy: "Trustworthy & Professional",
  TotalFreelance: "Total Freelancer",
  Positive: "Positive Review",
  Order: "Order received",
  Projects: "Projects Completed",
  Files: "Files",
  File: "File",
  addAmt: "Please add amount.",
  amt: "Amount",
  suffAmt: "Please send sufficient amount for transaction fee",
  greaterThen0: "Please add amount greater than 0.",
  editProporal: "Edit Proposal",
  selectDel: "Please select delivery time.",
  coverLet: "Cover letter should be at least 10 characters long.",
  Trusted: "Trusted By The World’s Best",
  BrowseTalent: "Browse Talent By Category",
  Find: "Find the perfect skills and experience for your next project with just a few clicks. WorkWaveHub makes it simple!",
  All: "All Categories",
  Website: "Website, IT & Software",
  Design: "Design, Media & Architecture",
  Data: "Data Entry & Admin",
  Education: "Education",
  Artificial: "Artificial Intelligence",
  Talented: "Talented Pool are here for your Business – Iran Work Marketplace",
  Search:
    "Search and connect with a vast pool of skilled professionals across diverse industries. Our innovative platform streamlines the recruitment process, saving you time and resources. Find the perfect fit for your open positions – from experienced developers to marketing wizards and everything in between. Empower your business with top Iranian talent. Get started with WorkWaveHub today!",
  The: "The Best Talent",
  OurPlatform:
    "Our platform connects you with a vast pool of pre-vetted Iranian professionals across various industries.",
  Fast: "Fast Bids",
  WorkWaveHubs:
    "WorkWaveHub's streamlined process lets you quickly post jobs and receive bids from qualified candidates.",
  Quality: "Quality Work",
  Our: "Our focus on skilled professionals ensures you receive high-quality work from qualified and skilled professionals.",
  Be: "Be in Control",
  WorkWaveHub2:
    "WorkWaveHub's platform provides clear communication tools and secure payment options, giving you complete control over the entire hiring process.",
  How: "How does WorkWaveHub works?",
  Create: "Create your profile",
  Showcase:
    "Showcase your skills and experience to stand out to top Iranian companies.",
  FindPerfect: "Find perfect jobs",
  SearchBrowse:
    "Search and browse thousands of opportunities across diverse industries in Iran.",
  SimpleApp: "Simple Application",
  Apply:
    "Apply to jobs with a single click and connect with potential employers.",
  Manage: "Manage your career",
  Track:
    "Track applications, receive job alerts, and manage your career journey on WorkWaveHub.",
  Love: "Love To Earn With Workwavehub",
  Clients: "Clients rate professionals on Workwavehub",
  Customers: "95% of customers are satisfied through to see their freelancers",
  AwardWinner: "Award Winner",
  HomeOw: "Home Ownership",
  Followus: "Follow us",
  Terms: "Terms of Service",
  Privacy: "Privacy Policy",
  Site: "Site Map",
  AboutOne: "About",
  CategoriesOne: "Categories",
  WebsiteSoft: "Website, IT & Software",
  DesignMedia: "Design, Media & Architecture",
  DataEntry: "Data Entry & Admin",
  Sales: "Sales & Marketing",
  Product: "Product Sourcing & Manufacturing",
  Translation: "Translation & Languages",
  Freight: "Freight, Shipping & Transportation",
  EducationOne: "Education",
  ArtificialIntel: "Artificial Intelligence",
  Health: "Health Care Service",
  Jobs: "Jobs for Anyone",
  Support: "Support",
  HelpSupport: "Help & Support",
  Trust: "Trust & Safety",
  Subscribe: "Subscribe",
  Your: "Your email address",
  Send: "Send",
  Downloads: "Downloads From",
  AllRights: "All Rights Reserved @workwavehub",
  Explore: "Explore your career potential in Iran with WorkWaveHub.",
  FindJob: "Find exciting job opportunities across various industries.",
  ShowcaseSkill: "Showcase your skills and experience to top companies.",
  Get: "Get matched with perfect jobs based on your interests.",
  ManageJob: "Manage your job search efficiently with WorkWaveHub tools.",
  Already: "Already have an account?",
  JoinUs: "Join Us!",
  JoinGrow: "Join our growing community of professionals in Iran.",
  Employee: "Employee",
  Personal: "Personal account to manage all your activities.",
  Employer: "Employer",
  Own: "Own or belong to a company, this is for you.",
  Register: "Register Employer Account!",
  RegisterEmployee: "Register Employee Account!",
  WorkWaveHub:
    "WorkWaveHub is your one-stop application to find the perfect talent fit in Iran. We use clever tech to match skills and interests for your jobs.",
  First: "First Name*",
  Last: "Last Name",
  Email: "Email address*",
  password: "Password",
  CreatePass: "Create password*",
  I: "I agree to terms & conditions",
  SignUp: "Sign up",
  RegisterGoogle: "Register with Google",
  Please: "Please Verify Your Phone Number",
  joinWWh:
    "Join the WorkWaveHub community with confidence. Verify your number today!",
  Phone: "Phone number",
  SendCode: "Send Code",
  YourInfo: "Your Info is safely secured",
  Almost:
    "Almost There! Verify Your Phone Number to Secure Your Account. Verifying your phone number is a quick step to complete your registration.",
  We: "We have sent a verification code to your phone number. Enter the code below to complete your WorkWaveHub registration.",
  Didnts:
    "Didn't receive the code? It usually arrives within 30 seconds. You can resend it if it takes longer.",
  OTP: "OTP Verification",
  WeOTP: "We have sent an OTP to your phone number!",
  DidntOne: "Didn't receive OTP?",
  resendOTP: "Resend OTP?",
  Verify: "Verify",
  Skills: "Skills That Help You Get A Job",
  WorkWaveHubHelp:
    "WorkWaveHub can help you find jobs that match your amazing skills! Make a great profile today!",
  Suggested: "Suggested Skills",
  Save: "Save & Continue",
  Complete: "Complete Your Profile!",
  YourChance:
    "Your profile is your chance to stand out from the crowd. Show your well-rounded and interesting.",
  User: "User Name",
  Next: "Next",
  Tell: "Tell us a bit about yourself",
  Tell2: "Tell us your Skills",
  Show: "Show employers why you are unique and what you can bring to the table.",
  What: "What do you do?",
  Describe: "Describe yourself",
  IStrong: "Let's get you started on your job search journey in Iran.",
  Darius: "Darius Farhadi, CEO",
  Welcome: "Welcome Back!",
  To: "To begin this journey, tell us what type of account you’d be opening.",
  EmailAddd: "Email address*",
  EnterEmailAdd: "Enter email address",
  EnterUserName: "Enter user name",
  EnterFullName: "Enter full name",
  Password: "Password*",
  Enter: "Enter your password",
  Remember: "Remember me",
  Forgot: "Forgot Password?",
  RegisterACCC: "Register Account",
  Login: "Login with Google",
  ToReset:
    "To reset your password, you need your email or mobile number that can be authenticated",
  Reset: "Reset Password",
  Back: "Back to Login",
  Check: "Check Your Email",
  Open: "Open Your Email",
  You: "You have not received the email? Resend",
  Successfully: "Successfully",
  YourPass1:
    "Your password has been updated, please change your password regularly to avoid this happening",
  Continue: "Continue",
  DesignCreative: "Design & Creative",
  Give: "Give your visitor a smooth online experience with a solid UX design",
  WhatLook: "What are you looking for?",
  Choose: "Choose Category",
  ITandSoftware: "IT & Software",
  Medical: "Medical",
  Architecture: "Architecture",
  SearchText: "Search",
  noChatFound: "No chat found!",
  Filters: "Filters",
  ProjectTypeOne: "Project Type",
  Hourly: "Hourly Rate",
  Fixed: "Fixed Price",
  BudgetOne: "Budget",
  Location: "Location",
  Clear: "Clear",
  Top: "Top Results",
  Sort: "Sort by",
  Latest: "Latest",
  Bids: "bids",
  Budget: "Budget",
  USD: "USD",
  minutes: "minutes ago",
  ProjectDetails: "Project Details",
  Skill: "Skill Required",
  Place: "Place Proposal on this project",
  Proposal: "Proposal Amount",
  HowProj: "How long will this project take?",
  Select: "Select",
  Cover: "Cover Letter",
  Attachments: "Attachments",
  Drag: "Drag or upload project files",
  Other: "Other Proposals",
  in: "in 7 days",
  Replies: "Replies within an hour",
  Similar: "Similar Projects",
  SendProp: "Send Proposal",
  Share: "Share this project with others",
  Copy: "Copy Link",
  Report: "Report Project",
  Client: "Client Verification",
  Dashboard: "Dashboard",
  Logout: "Logout",
  My: "My Proposals",
  Submitted: "Submitted Proposals",
  Archive: "Archive Proposals",
  ProjectName: "Project Name",
  Proposals: "Proposals",
  Average: "Average Proposal",
  MyProposal: "My Proposal",
  Action: "Action",
  Status: "Status",
  MyProj: "My Projects",
  AllPop: "All Proposals",
  SkillReq: "Skill Required",
  ProjectDetailsOne: "Project Details",
  Ongoing: "Ongoing Projects",
  Cost: "Cost",
  Duration: "Duration",
  selectCategoryFirst: "Please select category first",
  coinPayment: "Coin Payments",
  closeIn: "Close in",
  close: "Close",
  firstNameReq: "First Name is required",
  lastNameReq: "Last Name is required",
  stateNameReq: "State Name is required",
  crytpoReq: "Please enter Crypto address",
  Address: "Address",
  PaymentId: "Payment ID",
  timeLeft: "Time Left",
  Note: "Note",
  doNotReload:
    "PLEASE DO NOT GO BACK OR RELOAD THIS PAGE WHILE TRANSACTION IS IN PROCESS",
  hourRateReq: "Per hour rate cannot be less then 0",
  validCrypto: "Please enter valid USDT Crypto address",
  firstNameGreater3: "First name should be more than 3 characters",
  lastNameGreater3: "Last name should be more than 3 characters",
  inDays: "(in days)",
  MyBook: "My Bookmark",
  Bookmarked: "Bookmarked Projects",
  Message: "Message",
  SendMessage: "Send Message",
  Type: "Type a Message",
  perHour: "Per Hour",
  emailVerified: "Email verified",
  phoneVerified: "Phone number verified",
  phoneNotVerified: "Phone number not verified",
  Notifications: "Notifications",
  emailNotVerified: "Email not verified",
  AwardData:
    "The more your work on the WorkWaveHub platform, and complete tasks on time; the more you will make strong profile. The strong profile will help you to move different levels. For each job, you should get reviews from the job recruiter. These reviews can you give an advantage when bidding on projects and completing them successfully.",
  Delete: "Delete Chat",
  ActiveOne: "Active",
  MyProf: "My Profile",
  Profile: "Profile Details",
  Max: '"Max file size is 1MB, Minimum dimension: 330x300 And Suitable files are .jpg & .png"',
  Introduce: "Introduce Yourself",
  Languages: "Languages Level",
  Language: "Language",
  City: "City",
  Country: "Country",
  TypeOne: "Type",
  Specialization: "Specialization",
  Gender: "Gender",
  HourlyRate: "Hourly Rate",
  PhoneNo: "Phone number",
  EmailAdd: "Email Address",
  LastName: "Last Name",
  FirstName: "First Name",
  SkillsOne: "Skills",
  SkillsTwo: "Skills 1",
  Point: "Point",
  Are: "Are you sure you want to logout?",
  Cancel: "Cancel",
  Yes: "Yes",
  RegisterEmployee: "Register Employee Account!",
  WorkWaveHubIran:
    "WorkWaveHub is your one-stop application to find the perfect talent fit in Iran. We use clever tech to match skills and interests for your jobs.",
  Full: "Full Name",
  UserNameOne: "User Name",
  PhoneOne: "Phone number",
  TermsAnd: "I agree to terms & conditions",
  SignupOne: "Sign up",
  OTPVerify: "OTP Verification",
  WeVerify:
    "We will send you a verification code via SMS. Just enter it in the app, and you are good to go.",
  SkillsInterst: "Skills and Interest You Are Looking For",
  Post: "Post jobs and hire the best talent for your job – it is that easy!",
  SuggestedSkill: "Suggested Skills",
  SaveContinue: "Save & Continue",
  SavePost: "Save & Post",
  CreateProj: "Create Project",
  BrowseProf: "Browse Profile",
  HireSkill:
    "Hire skilled independent professionals for any project, over the internet.",
  LargestHub: "Iran's largest hub for independent professionals.",
  JobOpputunities: "All types of job opportunities",
  EnjoySavings: "Enjoy savings of up to 90% and receive free quotes.",
  PayOnly: "Pay only upon your satisfaction.",
  FoPurpose:
    "For the purpose of industry regulation, your details are required.",
  MaxRow: "Maximum 4 Rows",
  Submite: "Submit",
  EnterPass: "Enter password",
  ThiHelp: "This helps us recommend jobs for you.",
  ShownEmployee:
    "Show employers why you are unique and what can you bring to the table.",
  WriteLine: "Write a one line description about yourself.",
  BeginJourney:
    "To begin this journey, tell us what type of account you’d be opening",
  LoginEmployee: "Login",
  SignUpEmployee: "Sign Up",
  DontHavAccount: " Don’t have an account?",
  BackToHome: "Back to home",
  FullNameIsRequired: "Full Name is required helllo",
  EmailIsRequired: "Email is required",
  PasswordIsRequired: "Password is required",
  FullNameAtleastThree: "Full name should be more than 3 characters",
  InvalidEmailFormat: "Invalid email format",
  detailsPRojectErr: "Project Detail should be at least 10 characters long.",
  PassWordMustBe8Characters:
    "Password must be at least 8 characters long and include one capital letter. one small letter and one special character",
  YouMustAgree: "You must agree to the Terms & Conditions",
  UserNameIsRequired: "User Name is required",
  UserNameIs3Characters: "User name should be more than 3 characters",
  PleaseEnterPhoneNumber: "Please enter phone number",
  PleaseEnterValidNumber: "Please enter a valid phone number",
  Browse: "Browse",
  CreateProjectAndStartActivities: "Create Projects and start your activities.",
  BrowseProfileAndStartActivities: "Browse Profiles and start your activities.",
  UploadAttachments: "Upload Attachments",
  NoDocumentsFound: "No Documents found",
  BackButton: "Back",
  FileName: "FileName",
  CompletedProjects: "Completed Projects",
  Milestones: "Milestones",
  Milestone: "Milestone",
  Balance: "Balance",
  addBillingMethod: "Add Billing Method",
  paypalPaymentDes: "Secure online payment through the PayPal portal",
  stripePaymentDes: "Secure online payment through the Stripe portal",
  cryptoPaymentDes: "Secure online payment through the Crypto portal",
  deleteProject: "Are you sure you want to delete project?",
  Payment: "Payment",
  MileStoneName: "Milestone Name",
  hireFor: "Hire for",
  subtotal: "Subtotal",
  marketPlaceFee: "Marketplace Fee",
  pay: "Pay",
  estimatedTotal: "Estimated Total",
  addNewMilestone: "Add New Milestone",
  Document: "Document",
  Filter: "Filter",
  MyBookmarks: "My Bookmarks",
  BookmarkProjects: "Bookmark Projects",
  MyProfile: "My Profile",
  Skills: "Skills",
  ChangePassword: "Change Password",
  DeleteAccount: "Delete Account",
  VerifyEmail: "Verify Email",
  State: "State",
  Save: "Save",
  WhatAreTheMainSkills: "What are the main skill required for your work ?",
  onceDeleted: `Once deleted, recovering the data may not be possible. Click "Yes" to confirm, or "Cancel" to return.`,
  areYouSure: "Are you sure you want to delete?",
  acceptProject: "Are you sure you want to accept the project?",
  rejectProject: "Are you sure you want to reject the project?",
  UpdateSkill: "Update Skill",
  OldPassword: "Old Password",
  EnterOldPassword: "Enter Old Password",
  NewPasswod: "New Password",
  EnterNewPassword: "Enter New Password",
  ConfirmPassword: "Confirm New Password",
  EnterConfirmPassword: "Enter Confirm New Password",
  CloseAccount: "Close account",
  WarningCloseAccount:
    "Warning: If you close your account, you will be  unsubscribed from all your subscription and will lose access forever.",
  DeleteAccountEmployee: "Delete",
  EmailAlreadyVerified: "Email Already Verified",
  SendOTP: "Send OTP",
  PleaseEnterOpt: "Please enter the OTP",
  OTP6Digit: "OTP should be 6 digits",
  NewPasswodRequired: "New Password is required",
  ConfirmPasswordRequired: "Confirm Password is required",
  PassWordNotMatch: "Passwords do not match",
  SentOPT: "We have sent an OTP to your phone number !",
  PleaseAddSkills: "Please add skills",
  BrowseProject: "Browse Project",
  DashboardBookMark: "Bookmark",
  DashboardProfile: "Profile",
  Bids: "Project ends in ",
  SkillsRequired: "Skills Required",
  TimeDuration: "Time Duration (in days)",
  AwardProject: "Award project to",
  sendAnOffer: "Send an offer",
  projectMilestone: "Project milestones",
  CoverLetter: "Cover Letter",
  EnterProjectDetails: "Enter Your Project Detail",
  NoProposalFound: "No Proposal found",
  UpdateProposal: "Update Proposal",
  PostedProjects: "Posted Projects",
  Chat: "Chat",
  Award: "Award",
  NoDataFound: "No Data Found",
  EditMileStone: "Edit Milestone",
  MileStoneTitle: "Milestone Title",
  MileStoneDescription: "Milestone Description",
  DepositeAmount: "Deposit Amount",
  UpdateMileStone: "Update",
  DeleteMilestone: " Are you sure you want to delete milestone?",
  Finish: "Finish",
  AddMileStone: "Add Milestone",
  LeaveAReview: "Leave a Review",
  HowToYouRating: "How would you rate working ?",
  WriteAReview: "Write a review...",
  SubmitReview: "Submit Review",
  BasicInformation: "Basic Information",
  Category: "Category",
  selectOpt: "Please select any one option",
  enterTitle: "Please enter title",
  enterMsg: "Please enter message",
  titleCharErr: "Title should be at least 3 characters long.",
  cryptoAdd: "Crypto wallet address",
  getOTP: "Get OTP",
  ProjectTitle: "Project Title",
  ProjectDescription: "Project Description",
  ProjectDuration: "Project Duration",
  EstimateBudget: "Estimate Budget",
  MinBudegte: "Min. Budget",
  MaxBudget: "Max. Budget",
  ProjectTitle3: "Project Title should be at least 3 characters long.",
  ProjectDetail25: "Project Detail should be at least 25 characters long.",
  PleaseSelectCategory: "Please select a category.",
  PleaseSelectPaymentType: "Please select payment type",
  PleaseSelectSkills: "Please select skills.",
  PleaseSelectLocation: "Please select location",
  PleaseSelectDuration: "Please select project duration",
  PleaseSelectPrice: "Please select price",
  PleaseSelectMinBudget: "Please select min budget",
  PleaseSelectMaxBudget: "Please select max budget",
  DragandDrop: "Drag and drop an image here or click",
  MaxBudgetShould:
    "Max Budget should not be greater than the selected Estimate Budget",
  EnterValidNumber: "Please enter a valid number",
  MaxMinGreater: "Max Budget should be greater than Min Budget",
  MaxBudgetGreater: "Please enter max budget greater than estimate budget",
  MinBudgetShouldBe:
    "Min Budget should not be greater than the selected Estimate Budget",
  EditProject: "Edit Project",
  ProjectType: "Please select project type",
  DepositeAmount: "Deposit Amount",
  PnoneAlreadyExists: "PleaseEnterValidNumber",
  SessionExpires:
    "Your session will end. To return, you will need to log back in. Are you sure you want to proceed?",
  Settings: "Settings",
  BillingandPayments: "Billing & Payments",
  EmployeeAbout: "Employee About",
  Reviews: "Reviews",
  NoReviews: "No reviews present at the moment.",
  AwardEmployee: "Award Employee",
  AwardEmployeeSeek:
    "I'm seeking an experienced and efficient app developer to create an innovative social networking application for iOS platform.",
  AwardRequest: "Award Request",
  Verification: "Verification",
  TopSkills: "Top Skills",
  TitleShouldBe5Characters: "Title should be at least 5 characters long.",
  DescriptionShouldBe5Characters:
    "Description should be at least 5 characters long.",
  PleaseAddTime: "Please add time.",
  validDepoistAmt: "Please add a valid deposit amount.",
  greaterDepoistAmt: "Please add deposit amount greater than 0.",
  PleaseAddDeposite: "Please add deposite amount.",
  PleaseGiveStar: "Please give atleast 1 star.",
  ReviewShouldbe5: "Review should be at least 5 characters long.",
  LoadMore: "Load more",
  DonateOneTake: "Help Bring Light to Young Lives in Iran",
  DonateTwoTake:
    "Let us make a positive impact on the lives of deserving children together. Donate today and help light the way for a brighter tomorrow.",
  Dontae: "Donate",
  FindSol: "Find Solutions Fast to Get Started on WorkWaveHub",
  LetsGet: "Let's Get You Some Help.",
  HaveTrouble:
    "If you're having trouble with your account, a project, or payments, don't worry! Our support team is always happy to help.",
  HowWeCan: "How we can help you?",
  TitleContact: "Title",
  describePlease: "Please describe your needs",
  contactone:
    " Is there a problem, or is it just a question? You can inform or ask us anything.",
  contacttwo: "Get in Touch",
  PricingFees: "Pricing & Plans",
  privacyPolicy: "Privacy Policy",
  privacyPolicy: "Privacy Policy",
  privacyPolicy1: `This Privacy Policy outlines our practices regarding the collection,
            use, and disclosure of your information when you use our service
            (the "Service"). By accessing or using the Service, you agree to the
            collection and use of your information in accordance with this
            policy.`,
  privacyPolicy2: `Privacy Policy Link`,
  privacyPolicy3: `The Privacy Policy can be accessed at the following link:`,
  privacyPolicy4: `Entity Reference`,
  privacyPolicy5: `The entity responsible for your Personal Data is WorkWaveHub,
              referred to as "we," "us," or "our."`,
  privacyPolicy6: `Policy Labeling`,
  privacyPolicy7: `This document is labeled as the "Privacy Policy" and outlines our
              practices regarding the handling of personal data.`,
  privacyPolicy8: `Account`,
  privacyPolicy9: ` : A unique account
                created for you to access our Service.`,
  privacyPolicy10: `Affiliate`,
  privacyPolicy11: `: An entity that
                controls, is controlled by, or is under common control with a
                party.`,
  privacyPolicy12: `Application`,
  privacyPolicy13: `: Refers to WorkWaveHub,
                the software provided by us.`,
  privacyPolicy14: `Company`,
  privacyPolicy15: `: WorkWaveHub, referred
                to as "we," "us," or "our."`,
  privacyPolicy16: `Cookies`,
  privacyPolicy17: `: Small files placed on
                your device that contain details of your browsing history.`,
  privacyPolicy18: `Country`,
  privacyPolicy19: `: Refers to Iran.`,
  privacyPolicy20: `Device`,
  privacyPolicy21: `: Any device capable of
                accessing the Service, such as a computer or mobile device.`,
  privacyPolicy22: `Personal Data`,
  privacyPolicy23: `: Information that
                relates to an identified or identifiable individual.`,
  privacyPolicy24: `Service`,
  privacyPolicy25: `: The Application or the
                Website or both.`,
  privacyPolicy26: `Service Provider`,
  privacyPolicy27: ` : Any entity that
                processes data on our behalf.`,
  privacyPolicy28: `Third-party Social Media Service`,
  privacyPolicy29: `: Websites or social
                networks allowing you to log in or create an account for the
                Service.`,
  privacyPolicy30: `Usage Data`,
  privacyPolicy31: `: Data collected
                automatically, generated by the Service or its infrastructure.`,
  privacyPolicy32: `Website`,
  privacyPolicy33: `: WorkWaveHub,
                accessible at`,
  privacyPolicy34: `You`,
  privacyPolicy35: `: The individual or legal
                entity accessing or using the Service.`,
  privacyPolicy36: `Collecting and Using Your Personal Data`,
  privacyPolicy37: `Types of Data Collected`,
  privacyPolicy38: `Contact Information`,
  privacyPolicy39: `While using Our Service, We may ask You to provide Us with certain
              personally identifiable information that can be used to contact or
              identify You. Personally identifiable information may include, but
              is not limited to:`,
  privacyPolicy40: `Email address`,
  privacyPolicy41: `First name and last name`,
  privacyPolicy42: `Phone number`,
  privacyPolicy43: `Physical Address`,
  privacyPolicy44: `For any questions about this Privacy Policy or if you would like
              to exercise your rights regarding your Personal Data, you can
              reach us at:`,
  privacyPolicy45: `Email`,
  privacyPolicy46: `Website`,
  privacyPolicy47: `Phone`,
  privacyPolicy48: `Accessibility`,
  privacyPolicy49: `The Privacy Policy is accessible in a standard web browser and
              does not require plugins or additional software. It is hosted on
              an active, public, non-geofenced URL and is non-editable by the
              public.`,
  privacyPolicy50: `Interpretation and Definitions`,
  privacyPolicy51: `Interpretation`,
  privacyPolicy52: `Words with initial capitalization have specific meanings defined
              here. These meanings apply whether used in singular or plural
              forms.`,
  privacyPolicy53: `Definitions`,
  privacyPolicy54: `For this Privacy Policy:`,
  privacyPolicy55: `We may use your Personal Data for various purposes, including:`,
  privacyPolicy56: `Providing and maintaining our Service.`,
  privacyPolicy57: `Managing your Account.`,
  privacyPolicy58: `Contacting you with updates and promotional information.`,
  privacyPolicy59: `Analyzing data to improve our Service.`,
  privacyPolicy60: `Sharing Your Personal Data`,
  privacyPolicy61: `We may share your personal information in the following
              situations:`,
  privacyPolicy62: `With Service Providers`,
  privacyPolicy63: `: To monitor and analyze
                usage.`,
  privacyPolicy64: `For Business Transfers`,
  privacyPolicy65: `: During negotiations of
                mergers or acquisitions.`,
  privacyPolicy66: `With Affiliates`,
  privacyPolicy67: `: With our parent company or
                subsidiaries.`,
  privacyPolicy68: `With Business Partners`,
  privacyPolicy69: `: To offer specific
                products or services.`,
  privacyPolicy70: `With Your Consent`,
  privacyPolicy71: `: For any other purpose you
                agree to.`,
  privacyPolicy72: `We retain your Personal Data only as long as necessary for the
              purposes outlined in this Privacy Policy. Usage Data is typically
              retained for shorter periods unless needed for security or
              compliance.`,
  privacyPolicy73: `Your information may be transferred to computers located outside
              your jurisdiction. By providing your information, you consent to
              this transfer. We ensure your data is treated securely and in line
              with this Privacy Policy.`,
  privacyPolicy74: `You have the right to delete or request assistance in deleting
              your Personal Data. You can manage your information via your
              Account settings or by contacting us.`,
  privacyPolicy75: `We may disclose your Personal Data in good faith under the
              following circumstances:`,
  privacyPolicy76: `We value your Personal Data's security but cannot guarantee
              absolute security. We utilize commercially acceptable measures to
              protect your information, including encryption, access controls,
              and secure servers.`,
  privacyPolicy77: `Use of Your Personal Data`,
  privacyPolicy78: `Legal Obligations`,
  privacyPolicy79: `: If required by law or in
                response to valid requests by public authorities.`,
  privacyPolicy80: `Protecting the Rights of the Company`,
  privacyPolicy81: `: To
                protect and defend our rights and property.`,
  privacyPolicy82: `Preventing Potential Wrongdoing`,
  privacyPolicy83: `: To prevent or
                investigate possible wrongdoing in connection with the Service.`,
  privacyPolicy84: `Ensuring the Safety of Users`,
  privacyPolicy85: `: To protect the
                personal safety of users of the Service or the public.`,
  privacyPolicy86: `Our Service is not intended for anyone under 13. We do not
              knowingly collect Personal Data from minors. If you are a parent
              or guardian and believe we have collected information from your
              child, please contact us immediately.`,
  privacyPolicy87: `Our Service may contain links to third-party websites. We are not
              responsible for their content or privacy practices. We encourage
              you to review their privacy policies.`,
  privacyPolicy88: `We may update our Privacy Policy periodically. We will notify you
              of changes by posting the new policy on this page, along with the
              "Last updated" date.`,
  privacyPolicy89: `Detailed Disclosures`,
  privacyPolicy90: `Types of Personal and Sensitive Data Collected`,
  privacyPolicy91: `We may collect personal data, including but not limited to:`,
  privacyPolicy92: `Third-Party Access`,
  privacyPolicy93: `We may share your data with third parties as described in this
              Privacy Policy, including analytics and advertising partners. We
              ensure that these third parties handle your data responsibly and
              in compliance with applicable regulations.`,
  privacyPolicy94: `Security Measures`,
  privacyPolicy95: `Retention of Your Personal Data`,
  privacyPolicy98: `Transfer of Your Personal Data`,
  privacyPolicy99: `Your information, including Personal Data, is processed at the
              Company's operating offices and in any other places where the
              parties involved in the processing are located. It means that this
              information may be transferred to — and maintained on — computers
              located outside of Your state, province, country or other
              governmental jurisdiction where the data protection laws may
              differ than those from Your jurisdiction.`,
  privacyPolicy100: `We implement security measures to protect your data, including
              encryption, access controls, and secure servers.`,
  privacyPolicy101: `Data Retention and Deletion Policies`,
  privacyPolicy102: `Disclosure of Your Personal Data`,
  privacyPolicy103: `We retain your Personal Data only for as long as necessary to
              fulfill the purposes outlined in this policy. You can request
              deletion of your Personal Data at any time, and we will comply in
              accordance with applicable laws.`,
  privacyPolicy104: `User Rights`,
  privacyPolicy105: `Users have the right to:`,
  privacyPolicy106: `You can access your personal data via your account settings or
                by contacting us.`,
  privacyPolicy107: `You can request corrections through your account settings or by
                contacting us directly.`,
  privacyPolicy108: `To request deletion, please contact us at the provided email:`,
  privacyPolicy109: `Compliance with Google Play Policies`,
  privacyPolicy110: `This Privacy Policy is designed to comply with Google Play
              Developer Program Policies. We are committed to protecting your
              privacy and ensuring that our practices align with industry
              standards. Specifically, we have implemented the following
              measures:`,
  privacyPolicy111: `Link on Store Listing`,
  privacyPolicy112: `: This privacy policy is
                prominently linked on our Google Play store listing to provide
                easy access for users.`,
  privacyPolicy113: `Reference in App`,
  privacyPolicy114: `Security of Your Personal Data`,
  privacyPolicy115: `: Within the app, we
                provide a clear reference to this privacy policy, ensuring users
                can easily find and understand how their data is managed.`,
  privacyPolicy116: `Children's Privacy`,
  privacyPolicy117: `Clear Labeling`,
  privacyPolicy118: `: This document is clearly
                labeled as the "Privacy Policy" to avoid any confusion regarding
                its purpose and content.`,
  privacyPolicy119: `Links to Other Websites`,
  privacyPolicy120: `Contact Point`,
  privacyPolicy121: `: We have provided a dedicated
                contact point above for user inquiries about privacy, allowing
                users to reach out with any questions or concerns regarding
                their personal data.`,
  privacyPolicy122: `Changes to this Privacy Policy`,
  privacyPolicy123: `Accessibility`,
  privacyPolicy124: `: This privacy policy is hosted on
                an active, public URL that is non-geofenced and fully accessible
                in standard web browsers, ensuring users can easily read and
                understand our privacy practices.`,
  privacyPolicy125: `Data Handling Transparency`,
  privacyPolicy126: `: We are transparent
                about how we collect, use, and share your personal data, as
                outlined in this policy. Users are encouraged to review this
                document to understand their rights and our responsibilities
                concerning their information.`,
  privacyPolicy127: `Payment Information and Data Storage`,
  privacyPolicy128: `When you make a payment through our platform, we collect and store
              certain payment-related information to facilitate your
              transactions. We do not directly store payment methods such as
              credit card numbers, but we may retain other data related to
              payment transactions, as described below:`,
  privacyPolicy129: `Transaction ID`,
  privacyPolicy130: `: For third-party payment
                processors (e.g., Stripe, PayPal, CoinPayment), we store the
                Transaction ID for reference and tracking purposes.`,
  privacyPolicy131: `Delete Your Personal Data`,
  privacyPolicy132: `USDT Crypto Address`,
  privacyPolicy133: `: If you use cryptocurrency
                for payments, we store the USDT crypto address associated with
                your account to complete the transaction.`,
  privacyPolicy134: `We only retain this data as necessary for fulfilling legal and
              regulatory obligations, dispute resolution, and transactional
              record-keeping. Your payment information is processed securely by
              third-party payment processors, and we ensure that our partners
              comply with relevant privacy and security standards.`,
  pricingPlans: `Pricing & Fees`,
  deletionPolicy: `Deletion Policy`,
  deletionPolicy1: `Account Deletion: You may choose to delete your account on the
                Platform (i.e. the website `,
  deletionPolicy2: `and/or the mobile application workwavehub) and any information
                associated with it, at any point of time. Such a request for
                deletion must be sent in writing to `,
  deletionPolicy3: ` or you can login into your account and check for delete account
                option where you can delete your existing account . In the event
                that You make a request for deletion of Your account or data,
                you will no longer be able to access the Platform and/or use the
                Services, and we reserve the right to not provide You any
                Services through the Platform. In the event of your request, We
                may delete Your information (Personal or otherwise) or
                de-identity it so that it is anonymous and no longer
                attributable to You. Notwithstanding anything contained in the
                Privacy Policy, We may retain some or all of Your information in
                accordance with our policies and/or requirements under
                applicable law. Terms not defined herein shall have the meaning
                assigned to them in the Privacy Policy.`,
  termsAndCondition: "Terms & condition",
  trustAndSafety: "Trust And Safety",
  trustAndSafety1: `Finding the Perfect Service Provider Just Got Easier`,
  trustAndSafety2: `When it comes to finding the best tools and services for your
            business, reviews, and reputation matter. That is why the
            WorkWaveHub marketplace makes it easy to compare, choose, and trust
            the vendors you work with.`,
  trustAndSafety3: `Here is how:`,
  trustAndSafety4: `Seller Ratings & Levels:`,
  trustAndSafety5: `See what other
              businesses think! WorkWaveHub displays clear seller ratings and
              levels, giving you a quick snapshot of each vendor's performance.`,
  trustAndSafety6: `Buyer Feedback:`,
  trustAndSafety7: `Go beyond ratings by reading real
              customer reviews. This lets you see firsthand what other
              businesses have experienced working with a particular seller.`,
  trustAndSafety8: `With all this information at your fingertips, you can make informed
            decisions and find the perfect service providers to help your
            business thrive!`,
  trustAndSafety9: `Making informed decisions is key to getting the most out of
            WorkWaveHub. That is why we make it easy to see what other
            businesses think of the sellers you are considering.`,
  trustAndSafety10: `Seller Ratings:`,
  trustAndSafety11: `After a seller finishes a project
              (called a Gig on WorkWaveHub), buyers can rate their experience.
              These ratings are based on clear criteria, so you can see exactly
              how the seller performed in different areas.`,
  trustAndSafety12: `Buyer Reviews:`,
  trustAndSafety13: `Want more than just a star rating?
              No problem! Buyers can also leave written reviews about their
              experience working with a seller. This lets you see what other
              businesses liked (or did not like) about working with them.`,
  trustAndSafety14: `Buy and Sell with Confidence`,
  trustAndSafety15: `Millions of transactions happen on WorkWaveHub every day. We know
            security is important, so we use the latest and greatest technology
            to keep your money and information safe. That means you can focus on
            finding the perfect service or gig, without worrying about scams or
            data breaches.`,
  trustAndSafety16: `Information Stays Private:`,
  trustAndSafety17: `We value your privacy
              and will never share your personal details with anyone outside of
              WorkWaveHub. Your data is secure with us, always.`,
  trustAndSafety18: `Safe and Secure Payments:`,
  trustAndSafety19: `No need to worry about
              your money or credit card information. All transactions happen
              securely on our platform, whether you pay with credit card,
              PayPal, or another method. We follow the highest security
              standards to keep your information safe.`,
  trustAndSafety20: `Communicate with Confidence:`,
  trustAndSafety21: `Our secure messaging
              system lets you chat and share files with any seller on
              WorkWaveHub without worry.`,
  pricingFees: `For employees`,
  pricingFees1: `As a freelance employee on WorkWaveHub, you will incur a service
                fee on your earnings. The fee structure is as follows:`,
  pricingFees2: `Projects equal to or exceeding $200`,
  pricingFees3: `A 7% service charge is applied.`,
  pricingFees4: `Example`,
  pricingFees5: `For a $200 project, the service charge is
                      7%, which amounts to $14. Your final payment will be $186.`,
  pricingFees6: `For a $300 project, the
                      service charge is 7%, which amounts to $21. Your final
                      payment will be $279.`,
  pricingFees7: `Projects under $200`,
  pricingFees8: `A 9% service charge is applied.`,
  pricingFees9: `For a $150 project, the
                      service charge is 9%, which amounts to $13.50. Your final
                      payment will be $136.50.`,
  pricingFees10: `You must provide a valid crypto address when creating your work
                account. Once you complete a project and receive valid approval
                from the employer, your payment will be processed within 24
                hours to your crypto account in USDT.`,
  pricingFees11: `For employers`,
  pricingFees12: ` As an employer on WorkWaveHub, you will incur a service fee on
                the project cost. The fee structure is as follows:`,
  pricingFees14: `A 7% service charge is applied.`,
  pricingFees15: `For a $200 project, the
                      service charge is 7%, which amounts to $14. Your total
                      amount to add will be $214.`,
  pricingFees16: `For a $300 project, the
                      service charge is 7%, which amounts to $21. Your total
                      amount to add will be $321.`,
  pricingFees17: `A 9% service charge is applied.`,
  pricingFees18: `For a $150 project, the
                      service charge is 9%, which amounts to $13.50. Your total
                      amount to add will be $163.50.`,
  pricingFees19: `When creating a project, you will need to add the required funds
                via PayPal, Stripe, or Crypto. After you approve the completed
                project from the employee, the amount will be deducted from your
                WorkWaveHub fund and transferred to the freelance employee's
                account.`,
};

export default enTranslations;
